.App {
  text-align: center;
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}
